// Generated by Framer (2c4b293)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {rgumOedKd: {hover: true}};

const cycleOrder = ["rgumOedKd"];

const serializationHash = "framer-Bi1qe"

const variantClassNames = {rgumOedKd: "framer-v-1cu0uy9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 100, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ZIu3aYGkmLIt5cXyEy, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rgumOedKd", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={ZIu3aYGkmLIt5cXyEy}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1cu0uy9", className, classNames)} framer-gvbme6`} layoutDependency={layoutDependency} layoutId={"rgumOedKd"} ref={ref ?? ref1} style={{background: "radial-gradient(103% 173% at 50% 50%, rgba(3, 95, 255, 0) 0%, rgba(3, 95, 255, 0) 4.335585585585583%, rgba(0, 40, 87, 0) 100%)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{"rgumOedKd-hover": {background: "radial-gradient(103% 173% at 50% 50%, rgb(3, 95, 255) 0%, rgb(3, 95, 255) 4.335585585585583%, rgb(0, 40, 87) 100%)"}}}><motion.div className={"framer-1bgbr7a"} layoutDependency={layoutDependency} layoutId={"uNvab7VvD"} style={{backgroundColor: "var(--token-b96a703f-fd6e-45ee-9e4e-70b3c5061d5a, rgb(2, 95, 255))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QXJjaGl2by01MDA=", "--framer-font-family": "\"Archivo\", \"Archivo Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-line-height": "22px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Buy Now</motion.p></React.Fragment>} className={"framer-w85er1"} fonts={["GF;Archivo-500"]} layoutDependency={layoutDependency} layoutId={"zylY5cXcW"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)"}} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Bi1qe.framer-gvbme6, .framer-Bi1qe .framer-gvbme6 { display: block; }", ".framer-Bi1qe.framer-1cu0uy9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 4px; position: relative; text-decoration: none; width: 510px; will-change: var(--framer-will-change-override, transform); }", ".framer-Bi1qe .framer-1bgbr7a { align-content: center; align-items: center; display: flex; flex: 1 0 0px; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px 16px 10px 16px; position: relative; width: 1px; }", ".framer-Bi1qe .framer-w85er1 { -webkit-user-select: none; flex: none; height: auto; pointer-events: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Bi1qe.framer-1cu0uy9, .framer-Bi1qe .framer-1bgbr7a { gap: 0px; } .framer-Bi1qe.framer-1cu0uy9 > *, .framer-Bi1qe .framer-1bgbr7a > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Bi1qe.framer-1cu0uy9 > :first-child, .framer-Bi1qe .framer-1bgbr7a > :first-child { margin-left: 0px; } .framer-Bi1qe.framer-1cu0uy9 > :last-child, .framer-Bi1qe .framer-1bgbr7a > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 510
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"KSNq3onW_":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruxKZAR8d5: React.ComponentType<Props> = withCSS(Component, css, "framer-Bi1qe") as typeof Component;
export default FrameruxKZAR8d5;

FrameruxKZAR8d5.displayName = "Button";

FrameruxKZAR8d5.defaultProps = {height: 50, width: 510};

addFonts(FrameruxKZAR8d5, [{explicitInter: true, fonts: [{family: "Archivo", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTBjNp9R1oJ0vyVQ.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})